#timeContainer {
    width: 100%;
}

#timeInteractives {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
}

#timeAlignment {
    text-align: center;
}

.timeKeeper {
    display: flex;
    justify-content: center;
}

.timeOverride .periodScroller {
    background-color: #a79f91;
    color: #696666;
    box-shadow: 0px 6px #a79f91;
    border: 2px solid #a79f91;
}

.timeOverride .periodScroller button {
    color: #696666;
    pointer-events: none;
}

button span {
    pointer-events: none;
}

.timeScroller {
    background-color: #FFB647;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 1.1em;
    padding: 0 0.5em;
    box-shadow: 0px 6px #d37024;
    border: 2px solid #d37024;
    border-radius: 0.8em;
}

.timeScroller button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #843030;
    border-radius: 0.5em;
    transition: background-color 0s, border-color 0s;
}

.timeScroller button:hover {
    background-color: #ffd493;
}

.timeScroller .icon {
    font-size: 0.7em;
}

.timeScroller .label {
    font-weight: 900;
}

.monthScroller {
    margin-right: 0.8em;
    width: 5.1em;
}

.periodScroller {
    transition: background-color 0s, border-color 0s;
    margin-left: 0.2em;
    width: 1.7em;
}

.anytimeButton,
.rightNowButton {
    background-color: #FFB647;
    color: black;
    text-align: center;
    border-radius: 0.3em;
    box-shadow: 0px 3px #d37024;
    border: 2px solid #d37024;
    font-family: "Nunito", serif;
    font-weight: 700;
    font-size: 0.5em;
    margin: 1em 0.5em;
    cursor: pointer;
}

.anytimeButton:hover,
.rightNowButton:hover {
    background-color: #ffd493;
    box-shadow: 0px 3px #e6873f;
    border: 2px solid #e6873f;
}

@media only screen and (min-width: 750px) {
    .timeScroller {
        font-size: 1.8em;
    }

    .timeScroller .label {
        padding: 0.2em 0;
    }

    .anytimeButton,
    .rightNowButton {
        font-size: 1em;
    }
}

@media only screen and (min-width: 992px) {
    .timeScroller {
        font-size: 2.5em;
    }

    .timeScroller .label {
        padding: 0.2em 0;
    }

    .anytimeButton,
    .rightNowButton {
        font-size: 1.5em;
    }
}

@media only screen and (min-width: 1200px) {
    .timeScroller {
        font-size: 2.2em;
    }

    .timeScroller .label {
        padding: 0.2em 0;
    }

    .anytimeButton,
    .rightNowButton {
        font-size: 1.3em;
    }
}

@media only screen and (min-width: 1900px) {
    .timeScroller {
        font-size: 2.5em;
    }

    .timeScroller .label {
        padding: 0.2em 0;
    }

    .anytimeButton,
    .rightNowButton {
        font-size: 1.5em;
    }
}