.footerContainer {
    width: 100%;
    text-align: center;
    color: '#47260a';
    font-size: 0.5em;
    padding: 1em 0;
    margin: auto auto 0 auto;
}

.nightTime .footerContainer {
    color: var(--font-color-darkmode);
}

@media only screen and (min-width: 750px) {
    .footerContainer {
        font-size: 1em;
    }
}

@media only screen and (min-width: 992px) {
    .footerContainer {
        font-size: 1.5em;
    }
}

@media only screen and (min-width: 1200px) {
    .footerContainer {
        font-size: 1em;
    }
}

@media only screen and (min-width: 1900px) {
    .footerContainer {
        font-size: 1.5em;
    }
}