#pricesContainer {
    text-align: center;
}

.showPriceText {
    font-weight: 600;
    font-size: 0.6em;
}

.toggleContainer {
    display: flex;
    align-items: center;
    margin-top: 0.1em;
}

.priceLabel {
    font-weight: 700;
    font-size: 0.7em;
}

.priceToggle {
    position: relative;
    display: inline-block;
    margin: 0 0.1em;
    width: 2.2em;
    height: 1.3em;
}

.priceToggle input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #99571C;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 1.5em;
}

.slider:before {
    position: absolute;
    content: "";
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
    height: 0.9em;
    width: 0.9em;
    left: 0.2em;
    bottom: 0.2em;
}

input:checked+.slider {
    background-color: #FD5C65;
}

input:focus+.slider {
    box-shadow: 0 0 1px #FD5C65;
}

.nightTime .slider {
    background-color: #7d4e24;
}

.nightTime input:checked+.slider {
    background-color: #d33e45;
}

input:checked+.slider:before {
    -webkit-transform: translateX(0.95em);
    -ms-transform: translateX(0.95em);
    transform: translateX(0.95em);
}

@media only screen and (min-width: 750px) {
    .showPriceText {
        font-size: 1em;
    }

    .toggleContainer {
        margin-top: 0.2em;
    }

    .priceLabel {
        font-size: 1.1em;
    }

    .priceToggle {
        margin: 0 0.3em;
        width: 2.8em;
        height: 1.7em;
    }

    .slider {
        border-radius: 1.5em;
    }

    .slider:before {
        height: 1.3em;
        width: 1.3em;
        left: 0.2em;
        bottom: 0.2em;
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(1.1em);
        -ms-transform: translateX(1.1em);
        transform: translateX(1.1em);
    }
}

@media only screen and (min-width: 1200px) {
    .showPriceText {
        font-size: 1em;
    }

    .toggleContainer {
        margin-top: 0.5em;
    }

    .priceLabel {
        font-size: 1.2em;
    }

    .priceToggle {
        margin: 0 0.3em;
        width: 3.5em;
        height: 2em;
    }

    .slider:before {
        height: 1.5em;
        width: 1.5em;
        left: 0.3em;
        bottom: 0.25em;
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(1.4em);
        -ms-transform: translateX(1.4em);
        transform: translateX(1.4em);
    }
}

@media only screen and (min-width: 1900px) {
    .showPriceText {
        font-size: 1.3em;
    }

    .toggleContainer {
        margin-top: 0.5em;
    }

    .priceLabel {
        font-size: 1.5em;
    }

    .priceToggle {
        margin: 0 0.3em;
        width: 3.5em;
        height: 2em;
    }

    .slider:before {
        height: 1.5em;
        width: 1.5em;
        left: 0.3em;
        bottom: 0.25em;
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(1.4em);
        -ms-transform: translateX(1.4em);
        transform: translateX(1.4em);
    }
}