.formContainer {
    color: #311a06;
    width: 70%;
    margin: 35% auto;
}

.topText {
    text-align: center;
    font-weight: 800;
    font-size: 1.2em;
    margin: 1em;
}

.nightTime .formContainer {
    color: var(--font-color-darkmode);
    text-shadow: 1px 1px 1px rgb(0, 0, 0, 0.4);
}

.errorText {
    text-align: center;
    font-size: 0.9em;
    color: rgb(145, 16, 16);
    font-weight: 600;
    align-items: center;
}

.nightTime .errorText {
    color: rgb(255, 176, 176);
}

.swapText {
    text-align: center;
    cursor: pointer;
    font-size: 0.6em;
}

.formContainer form {
    display: flex;
    flex-direction: column;
}

.formContainer form input {
    border: none;
    background: #FFFEFB;
    font-size: 0.8em;
    padding: 0.5em;
    margin: 0.3em 0;
    border-radius: 0.5em;
}

.formContainer button {
    border: none;
    font-weight: 700;
    color: white;
    text-shadow: 1px 1px 1px rgb(0, 0, 0, 0.4);
    cursor: pointer;
    border-radius: 0.5em;
    font-size: 0.8em;
    padding: 0.3em 0;
    width: 9em;
    margin: 1em auto;
}

@media only screen and (min-width: 750px) {
    .formContainer {
        width: 70%;
        margin: 25% auto;
    }

    .topText {
        font-size: 2em;
        margin: 1em;
    }

    .swapText {
        font-size: 1.2em;
    }

    .formContainer form input {
        font-size: 1.5em;
        padding: 0.8em;
        margin: 0.2em 0;
        border-radius: 0.5em;
    }

    .formContainer button {
        border-radius: 0.5em;
        font-size: 1.4em;
        padding: 0.3em 0;
        width: 9em;
        margin: 1em auto;
    }

    .errorText {
        font-size: 1.2em;
    }
}

@media only screen and (min-width: 992px) {
    .formContainer {
        width: 70%;
        margin: 25% auto;
    }

    .topText {
        font-size: 3em;
        margin: 1em;
    }

    .swapText {
        font-size: 1.8em;
    }

    .formContainer form input {
        font-size: 2em;
        padding: 0.8em;
        margin: 0.2em 0;
        border-radius: 0.5em;
    }

    .formContainer button {
        border-radius: 0.5em;
        font-size: 2em;
        padding: 0.3em 0;
        width: 9em;
        margin: 1em auto;
    }

    .errorText {
        font-size: 1.7em;
    }
}

@media only screen and (min-width: 1200px) {
    .formContainer {
        width: 40%;
        margin: 0 auto;
    }

    .topText {
        font-size: 2em;
        margin: 1em;
    }

    .swapText {
        font-size: 1.2em;
    }

    .formContainer form input {
        font-size: 1.5em;
        padding: 0.8em;
        margin: 0.2em 0;
        border-radius: 0.5em;
    }

    .formContainer button {
        border-radius: 0.5em;
        font-size: 1.5em;
        padding: 0.3em 0;
        width: 9em;
        margin: 1em auto;
    }

    .errorText {
        font-size: 1em;
    }
}

@media only screen and (min-width: 1900px) {
    .formContainer {
        width: 40%;
        margin: 0 auto;
    }

    .topText {
        font-size: 3em;
        margin: 1em;
    }

    .swapText {
        font-size: 1.8em;
    }

    .formContainer form input {
        font-size: 2em;
        padding: 0.8em;
        margin: 0.2em 0;
        border-radius: 0.5em;
    }

    .formContainer button {
        border-radius: 0.5em;
        font-size: 2em;
        padding: 0.3em 0;
        width: 9em;
        margin: 1em auto;
    }

    .errorText {
        font-size: 2em;
    }
}