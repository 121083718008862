#svgBanner {
    position: absolute;
    z-index: -1;
    width: 100%;
    top: 0;
    left: 0;
}

#shoreline {
    animation-name: shoreLine;
    animation-duration: 10s;
    animation-iteration-count: infinite;
}

@keyframes shoreLine {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    76% {
        opacity: 1;
    }

    95% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

#wave {
    transform: translate(510px, 239px);
    animation-name: waveIn;
    animation-duration: 10s;
    animation-iteration-count: infinite;
}

@keyframes waveIn {
    0% {
        opacity: 0;
        transform: translate(810px, 129px) scale(1);
    }

    40% {
        opacity: 1;
        transform: translate(480px, 265px) scale(1);
    }

    92% {
        opacity: 0;
        transform: translate(532px, 239px);
    }

    100% {
        opacity: 0;
    }
}

#twinkle0,
#twinkle1,
#twinkle2 {
  animation-name: twinkle;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

#twinkle1 {
  animation-delay: 2s;
}

#twinkle2 {
  animation-delay: 4s;
}

@keyframes twinkle {
    0% {
        opacity: 1;
    }

    40% {
        opacity: 0.1
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

#snowing_patch0,
#snowing_patch1,
#snowing_patch2 {
    animation-name: snowfall;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-delay: 0s;
}

#snowing_patch1 {
    animation-delay: 5s;
}

#snowing_patch2 {
    animation-duration: 9s;
    animation-delay: 3s;
}

.snowflake {
    animation-name: snowflakeDrift;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes snowfall {
    0% {}

    85% {
        opacity: 1
    }

    100% {
        transform: translateY(1300px);
        opacity: 0
    }
}

@keyframes snowflakeDrift {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(100px);
    }

    100% {
        transform: translateX(0)
    }
}

.stopAnimations #shoreline,
.stopAnimations #wave,
.stopAnimations #twinkle0,
.stopAnimations #twinkle1,
.stopAnimations #twinkle2,
.stopAnimations #snowing_patch0,
.stopAnimations #snowing_patch1,
.stopAnimations #snowing_patch2,
.stopAnimations .snowflake {
  animation: none;
}

/* aesthetically pleasing freeze-frame */
.stopAnimations #snowing_patch0 {
  transform: translateY(200px);
}
.stopAnimations #snowing_patch1 {
  transform: translateY(600px);
}
.stopAnimations #snowing_patch2 {
  transform: translateY(1500px);
}